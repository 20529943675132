<template>
  <section>
    <loading-flux :value="loading"></loading-flux>
    <v-dialog
      v-model="modalAction"
      max-width="800px"
      scrollable
      @keydown="executeCloseModalFilesSupplier"
      @click:outside="executeCloseModalFilesSupplier"
    >
      <v-card>
        <div class="p-4 pb-0" v-if="currentSupplier">
          <h5>
            Archivos del Proveedor: <b>{{ currentSupplier.official_name }}</b>
          </h5>
        </div>
        <hr />
        <v-card-text>
          <v-container class="">
            <ul v-if="currentSupplier">
              <li
                v-for="document in currentSupplier.documents"
                class="d-flex align-items-center"
              >
                <a :href="document.document_path" target="_blank">
                  <div class="d-flex align-items-center">
                    <v-icon small color="primary" class="mx-2">
                      mdi-file
                    </v-icon>
                    <p class="my-2">
                      {{ document.document_name }}
                    </p>
                  </div>
                </a>
                <v-btn
                  icon
                  class="mx-4"
                  color="red darken-2"
                  small
                  @click="deleteFile(document)"
                  v-if="
                    currentSupplier.status !== statusSupplier.created &&
                      showDeleteFileAndUpload
                  "
                >
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </li>
            </ul>
            <p
              class="text-center my-5"
              v-if="currentSupplier && !currentSupplier.documents.length"
            >
              Sin archivos cargados
            </p>
            <section
              class="mt-5 pt-3"
              v-if="
                currentSupplier &&
                  currentSupplier.status !== statusSupplier.created &&
                  showDeleteFileAndUpload
              "
            >
              <v-file-input
                accept="application/pdf"
                multiple
                counter
                show-size
                chips
                deletable-chips
                placeholder="Puedes cargar aqui los archivos restantes"
                :value="form.files"
                @change="updateFiles"
                @click:clear="form.files = []"
              ></v-file-input>
              <v-btn
                class="mt-5 float-right"
                color="primary"
                shaped
                small
                :disabled="!form.files.length"
                @click="updateFilesSupplier"
              >
                Subir Archivos
                <v-icon class="ml-2" small>
                  mdi-send
                </v-icon>
              </v-btn>
            </section>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            outlined
            @click="executeCloseModalFilesSupplier"
          >
            <small>
              Cerrar
            </small>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import { preventCloseIfNotKeyEventEsc } from "@/helpers/common";
import { STATUS_SUPPLIER } from "@/constants/supplier";
import { mapActions } from "vuex";
import { authUsuarioComputed } from "@/state/helpers";
import swal2 from "sweetalert2";
export default {
  props: {
    modalAction: {
      type: Boolean,
      default: false,
    },
    closeModalFilesSupplier: {
      type: Function,
      default: () => {},
    },
    currentSupplier: {
      default: null,
    },
    showDeleteFileAndUpload: {
      default: false,
    },
  },
  data() {
    return {
      statusSupplier: STATUS_SUPPLIER,
      loading: false,
      form: {
        files: [],
      },
      maxFilesDocuments: 5,
    };
  },
  methods: {
    ...mapActions({
      sendDeleteFile: "supplying/deleteFileSupplier",
      sendUpdateFilesSupplier: "supplying/updateFilesSupplier",
    }),
    executeCloseModalFilesSupplier($event) {
      let validationEvent = preventCloseIfNotKeyEventEsc($event);
      if (validationEvent) {
        this.closeModalFilesSupplier($event);
      }
    },
    updateFiles(newFiles) {
      if (newFiles !== this.form.files) {
        this.form.files = [...this.form.files, ...newFiles];
      }
    },
    async updateFilesSupplier() {
      let self = this;
      self.loading = true;
      if (
        self.form.files.length + self.currentSupplier.documents.length >
        self.maxFilesDocuments
      ) {
        self.loading = false;
        return swal2.fire({
          icon: "error",
          title: "Error",
          text:
            "El numero de archivos que intentas subir supera el limite de archivos permitidos: 5",
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 4000,
          timerProgressBar: true,
        });
      }
      const resp = await self.sendUpdateFilesSupplier({
        id: self.currentSupplier ? self.currentSupplier.id : undefined,
        user: self.user.email,
        files: self.form.files,
      });
      if (resp?.status == 200) {
        swal2.fire({
          icon: "success",
          title: "Excelente",
          text: "Archivos modificados correctamente",
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 5000,
          timerProgressBar: true,
        });
        self.form.files = [];
        self.$emit("filesUploaded", self.currentSupplier);
      }
      if (!resp?.status || resp?.status === 400) {
        self.loading = false;
        return swal2.fire({
          icon: "error",
          title: "Error",
          text: resp
            ? resp.data.detail
            : "Ha ocurrido un error de servidor, intentalo nuevamente",
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 10000,
          timerProgressBar: true,
        });
      }
      self.loading = false;
    },
    async deleteFile(file) {
      swal2
        .fire({
          icon: "info",
          title: `¿Estás seguro de eliminar este archivo: ${file.document_name}`,
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonColor: "red",
          confirmButtonText: "Eliminar",
          cancelButtonText: `Cancelar`,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.loading = true;
            let payload = {
              items: [
                {
                  id: file.id,
                  user: this.user.email,
                },
              ],
              supplier_id: this.currentSupplier.id,
            };
            const resp = await this.sendDeleteFile(payload);
            if (!resp || !resp.status || resp.status == 500) {
              swal2.fire({
                icon: "error",
                title: "Error",
                text: "Ha ocurrido un error en el servidor, intenta nuevamente",
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 3000,
                timerProgressBar: true,
              });
            }
            if (resp && resp.status == 200) {
              swal2.fire({
                icon: "success",
                title: "Excelente",
                text: `Archivo del proveedor eliminado correctamente`,
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 3000,
                timerProgressBar: true,
              });
            }
            this.loading = false;
          }
        });
    },
  },
  computed: {
    ...authUsuarioComputed,
  },
};
</script>

<style></style>
