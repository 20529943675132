<template>
  <section>
    <v-dialog
      v-model="modalAction"
      max-width="800px"
      scrollable
      @keydown="executeCloseModalPaymentInfoSupplier"
      @click:outside="executeCloseModalPaymentInfoSupplier"
    >
      <v-card>
        <div class="p-4 pb-0" v-if="currentSupplier">
          <h5>
            Información Bancaria del Proveedor:
            <b>{{ currentSupplier.official_name }}</b>
          </h5>
        </div>
        <hr />
        <v-card-text>
          <v-row
            v-if="currentSupplier"
            align-content="center"
            class="mb-2 mt-3"
          >
            <v-col cols="12" md="12" sm="12">
              <v-card-subtitle class="pb-0 font-weight-bold">
                Banco a Depositar
              </v-card-subtitle>
              <v-card-text class="text--primary">
                <div>
                  {{
                    currentSupplier.bank
                      ? currentSupplier.bank.name
                      : "Sin definir"
                  }}
                </div>
              </v-card-text>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-card-subtitle class="pb-0 font-weight-bold">
                Boleta / Factura
              </v-card-subtitle>
              <v-card-text class="text--primary">
                <div>
                  {{
                    currentSupplier.boleta_factura
                      ? currentSupplier.boleta_factura
                      : "Sin definir"
                  }}
                </div>
              </v-card-text>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-card-subtitle class="pb-0 font-weight-bold">
                Modo de Pago
              </v-card-subtitle>
              <v-card-text class="text--primary">
                <div>
                  {{
                    currentSupplier.payment_method
                      ? currentSupplier.payment_method
                      : "Sin definir"
                  }}
                </div>
              </v-card-text>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-card-subtitle class="pb-0 font-weight-bold">
                Numero de Cuenta
              </v-card-subtitle>
              <v-card-text class="text--primary">
                <div>
                  {{
                    currentSupplier.account_number
                      ? currentSupplier.account_number
                      : "Sin definir"
                  }}
                </div>
              </v-card-text>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-card-subtitle class="pb-0 font-weight-bold">
                Condición de Pago
              </v-card-subtitle>
              <v-card-text class="text--primary">
                <div>
                  {{
                    currentSupplier.payment_condition
                      ? currentSupplier.payment_condition
                      : "Sin definir"
                  }}
                </div>
              </v-card-text>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            outlined
            @click="executeCloseModalPaymentInfoSupplier"
          >
            <small>
              Cerrar
            </small>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import { preventCloseIfNotKeyEventEsc } from "@/helpers/common";
export default {
  props: {
    modalAction: {
      type: Boolean,
      default: false,
    },
    closeModalPaymentInfoSupplier: {
      type: Function,
      default: () => {},
    },
    currentSupplier: {
      default: null,
    },
  },
  data() {
    return {};
  },
  methods: {
    executeCloseModalPaymentInfoSupplier($event) {
      let validationEvent = preventCloseIfNotKeyEventEsc($event);
      if (validationEvent) {
        this.closeModalPaymentInfoSupplier($event);
      }
    },
  },
};
</script>

<style></style>
