<template>
  <section>
    <v-dialog
      v-model="modalAction"
      max-width="800px"
      scrollable
      @keydown="executeCloseModalContactInfoSupplier"
      @click:outside="executeCloseModalContactInfoSupplier"
    >
      <v-card>
        <div class="p-4 pb-0" v-if="currentSupplier">
          <h5>
            Información del contacto del Proveedor:
            <b>{{ currentSupplier.official_name }}</b>
          </h5>
        </div>
        <hr />
        <v-card-text>
          <v-row
            v-if="currentSupplier"
            align-content="center"
            class="mb-2 mt-3"
          >
            <v-col cols="12" md="12" sm="12">
              <v-card-subtitle class="pb-0 font-weight-bold">
                Nombre del contacto
              </v-card-subtitle>
              <v-card-text class="text--primary">
                <div>
                  {{
                    currentSupplier.contact_user_name
                      ? currentSupplier.contact_user_name
                      : "Sin definir"
                  }}
                </div>
              </v-card-text>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-card-subtitle class="pb-0 font-weight-bold">
                Correo electrónico del contacto
              </v-card-subtitle>
              <v-card-text class="text--primary">
                <div>
                  {{
                    currentSupplier.contact_user_email
                      ? currentSupplier.contact_user_email
                      : "Sin definir"
                  }}
                </div>
              </v-card-text>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-card-subtitle class="pb-0 font-weight-bold">
                Teléfono del contacto
              </v-card-subtitle>
              <v-card-text class="text--primary">
                <div>
                  {{
                    currentSupplier.contact_phone
                      ? currentSupplier.contact_phone
                      : "Sin definir"
                  }}
                </div>
              </v-card-text>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            outlined
            @click="executeCloseModalContactInfoSupplier"
          >
            <small>
              Cerrar
            </small>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import { preventCloseIfNotKeyEventEsc } from "@/helpers/common";
export default {
  props: {
    modalAction: {
      type: Boolean,
      default: false,
    },
    closeModalContactInfoSupplier: {
      type: Function,
      default: () => {},
    },
    currentSupplier: {
      default: null,
    },
  },
  data() {
    return {};
  },
  methods: {
    executeCloseModalContactInfoSupplier($event) {
      let validationEvent = preventCloseIfNotKeyEventEsc($event);
      if (validationEvent) {
        this.closeModalContactInfoSupplier($event);
      }
    },
  },
};
</script>

<style></style>
