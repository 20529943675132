var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('loading-flux',{attrs:{"value":_vm.loading}}),_c('v-dialog',{attrs:{"max-width":"800px","scrollable":""},on:{"keydown":_vm.executeCloseModalFilesSupplier,"click:outside":_vm.executeCloseModalFilesSupplier},model:{value:(_vm.modalAction),callback:function ($$v) {_vm.modalAction=$$v},expression:"modalAction"}},[_c('v-card',[(_vm.currentSupplier)?_c('div',{staticClass:"p-4 pb-0"},[_c('h5',[_vm._v(" Archivos del Proveedor: "),_c('b',[_vm._v(_vm._s(_vm.currentSupplier.official_name))])])]):_vm._e(),_c('hr'),_c('v-card-text',[_c('v-container',{},[(_vm.currentSupplier)?_c('ul',_vm._l((_vm.currentSupplier.documents),function(document){return _c('li',{staticClass:"d-flex align-items-center"},[_c('a',{attrs:{"href":document.document_path,"target":"_blank"}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('v-icon',{staticClass:"mx-2",attrs:{"small":"","color":"primary"}},[_vm._v(" mdi-file ")]),_c('p',{staticClass:"my-2"},[_vm._v(" "+_vm._s(document.document_name)+" ")])],1)]),(
                  _vm.currentSupplier.status !== _vm.statusSupplier.created &&
                    _vm.showDeleteFileAndUpload
                )?_c('v-btn',{staticClass:"mx-4",attrs:{"icon":"","color":"red darken-2","small":""},on:{"click":function($event){return _vm.deleteFile(document)}}},[_c('v-icon',[_vm._v(" mdi-delete ")])],1):_vm._e()],1)}),0):_vm._e(),(_vm.currentSupplier && !_vm.currentSupplier.documents.length)?_c('p',{staticClass:"text-center my-5"},[_vm._v(" Sin archivos cargados ")]):_vm._e(),(
              _vm.currentSupplier &&
                _vm.currentSupplier.status !== _vm.statusSupplier.created &&
                _vm.showDeleteFileAndUpload
            )?_c('section',{staticClass:"mt-5 pt-3"},[_c('v-file-input',{attrs:{"accept":"application/pdf","multiple":"","counter":"","show-size":"","chips":"","deletable-chips":"","placeholder":"Puedes cargar aqui los archivos restantes","value":_vm.form.files},on:{"change":_vm.updateFiles,"click:clear":function($event){_vm.form.files = []}}}),_c('v-btn',{staticClass:"mt-5 float-right",attrs:{"color":"primary","shaped":"","small":"","disabled":!_vm.form.files.length},on:{"click":_vm.updateFilesSupplier}},[_vm._v(" Subir Archivos "),_c('v-icon',{staticClass:"ml-2",attrs:{"small":""}},[_vm._v(" mdi-send ")])],1)],1):_vm._e()])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-1","outlined":""},on:{"click":_vm.executeCloseModalFilesSupplier}},[_c('small',[_vm._v(" Cerrar ")])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }